// FIXME: 구 데이터 전부 없어지면 삭제 ~
.description-content {
  th,
  td {
    padding: 12px 40px 12px 0;
    vertical-align: baseline;

    span,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin: 0;
      text-align: left;
      font-size: inherit;
      width: 180px;
    }
  }

  th,
  td:first-child {
    font-weight: bold;
    max-width: 220px;
  }

  @media screen and (max-width: 599px) {
    table {
      border: 2px solid #efefef;
      padding: 16px 10px;
      display: block;
      font-size: 11px;
    }

    tbody {
      display: block;
    }

    tr {
      display: flex;
      flex-direction: column;
    }

    tr:not(:last-child) {
      margin-bottom: 16px;
      border-bottom: 1px solid #efefef;
      padding-bottom: 16px;
    }

    td {
      padding: 0;
    }

    th,
    td:first-child {
      padding: 0 0 8px 0;
      max-width: fit-content;
      font-weight: bold;
    }
  }
}
// FIXME: ~ 구 데이터 전부 없어지면 삭제

.align_center {
  text-align: center;
}
