@forward 'at-rules';
@use 'at-rules' as *;

// html 기본 폰트 크기
$html-font-size: 16px;

// 미디어쿼리 모바일 max-width
$max-width-s: 599px;
// 미디어쿼리 태블릿 max-width
$max-width-m: 839px;
// 미디어쿼리 태블린 min-width
$min-width-m: 600px;
// 미디어쿼리 데스크톱 min-width
$min-width-l: 840px;

$tournity-color-blue: #15739e;

// 주 색상
$primary-color: #15739e;
// 주 텍스트 색상
$on-primary-color: getContrastColor($primary-color);

// 보조 색상
$secondary-color: #8abcd3;
// 보조 텍스트 색상
$on-secondary-color: getContrastColor($secondary-color);

// 보조 색상 2
$tertiary-color: #df2d1f;
// 보조 2 텍스트 색상
$on-tertiary-color: getContrastColor($tertiary-color);

@mixin primary-theme {
  @include color-theme($primary-color);
}

@mixin secondary-theme {
  @include color-theme($secondary-color);
}

@mixin tertiary-theme {
  @include color-theme($tertiary-color);
}

// 경고 색상
$warn-color: $tertiary-color;

// 주제 색
$title-color: #000;
// 부제 및 본문 색 1
$content-color-1: #2a2a2a;
// 부제 및 본문 색 2
$content-color-2: #333;
// 테두리 색 1
$border-color-1: #999;
// 테두리 색 2
$border-color-2: #f9f9f9;
// 비활성 요소 폰트 색
$disabled-color: $border-color-1;
// 비활성 요소 배경 색
$disabled-background-color: $border-color-2;

// 버튼, 인풋 등 모서리
$border-radius: 0px;

// 모바일 헤더 높이
$header-height-mobile: 50px; // 56px
// 데스크톱 헤더 높이
$header-height-desktop: 80px;
// 모바일 푸터 높이
$footer-height-mobile: 0px;
// 데스크톱 푸터 높이
$footer-height-desktop: 418px;

$font-family: 'メイリオ', 'Meiryo', 'M PLUS 1p', sans-serif, system-ui;
