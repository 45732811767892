// 각 컴포넌트에서 수정하고싶으면 :host 셀렉터 안에서 재정의
:root {
  --pw-header-height: 56px;
  --pw-footer-height: 56px;
}

// TODO: remove once Safari 11.2 is no longer supported
@supports (padding-top: constant(safe-area-inset-top)) {
  html {
    --pw-safe-area-top: constant(safe-area-inset-top);
    --pw-safe-area-bottom: constant(safe-area-inset-bottom);
    --pw-safe-area-left: constant(safe-area-inset-left);
    --pw-safe-area-right: constant(safe-area-inset-right);
  }
}

@supports (padding-top: env(safe-area-inset-top)) {
  html {
    --pw-safe-area-top: env(safe-area-inset-top);
    --pw-safe-area-bottom: env(safe-area-inset-bottom);
    --pw-safe-area-left: env(safe-area-inset-left);
    --pw-safe-area-right: env(safe-area-inset-right);
  }
}
