@import '/projects/pw-lib/src/styles/pw-layout.scss';
// pw 컴포넌트용 스타일

// FIXME: 샘플
// 각 컴포넌트에서 수정하고싶으면 :host 셀렉터 안에서 재정의
:root {
  --pw-header-height: 56px;
  --pw-footer-height: 64px;
}

// FIXME: 샘플
.pw-content-wrapper {
  background: yellow;
}

// FIXME: 샘플
.pw-footer-wrapper {
  border: 1px solid red;
  background: olivedrab;
}
