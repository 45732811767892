// 색상 밝기에 따른 텍스트 색상을 반환
// 어두운 색인 경우, $light. 밝은 색이면 $dark를 반환
@function getContrastColor($color, $light: #f9f9f9, $dark: #000) {
  $result: $light;
  @if (lightness($color) > 50%) {
    $result: $dark;
  }
  @return $result;
}

@mixin color-theme($color) {
  color: getContrastColor($color);
  background-color: $color;
}

@mixin multi-line-ellipsis($line-count) {
  display: -webkit-box;
  -webkit-line-clamp: $line-count;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-break: anywhere;
}
