@use '~@angular/material' as mat;
@use 'variable' as *;

@function getContrastColor($color) {
  $result: white;
  @if (lightness($color) > 50%) {
    $result: rgba(black, 0.87);
  }
  @return $result;
}

@function theme($color-500) {
  @return (
    50: lighten($color-500, 52%),
    100: lighten($color-500, 37%),
    200: lighten($color-500, 26%),
    300: lighten($color-500, 12%),
    400: lighten($color-500, 6%),
    500: $color-500,
    600: darken($color-500, 6%),
    700: darken($color-500, 12%),
    800: darken($color-500, 18%),
    900: darken($color-500, 24%),
    A100: saturate(lighten($color-500, 50%), 30%),
    A200: saturate(lighten($color-500, 30%), 30%),
    A400: saturate(lighten($color-500, 10%), 15%),
    A700: saturate(lighten($color-500, 5%), 5%),
    contrast: (
      50: getContrastColor(lighten($color-500, 52%)),
      100: getContrastColor(lighten($color-500, 37%)),
      200: getContrastColor(lighten($color-500, 26%)),
      300: getContrastColor(lighten($color-500, 12%)),
      400: getContrastColor(lighten($color-500, 6%)),
      500: getContrastColor($color-500),
      600: getContrastColor(darken($color-500, 6%)),
      700: getContrastColor(darken($color-500, 12%)),
      800: getContrastColor(darken($color-500, 18%)),
      900: getContrastColor(darken($color-500, 24%)),
      A100: getContrastColor(saturate(lighten($color-500, 50%), 30%)),
      A200: getContrastColor(saturate(lighten($color-500, 30%), 30%)),
      A400: getContrastColor(saturate(lighten($color-500, 10%), 15%)),
      A700: getContrastColor(saturate(lighten($color-500, 5%), 5%)),
    )
  );
}

/* Theme_primary */
$theme-primary-500: #15171d;
/* /Theme_primary */
/* Theme_secondary */
$theme-secondary-500: #15171d;
/* /Theme_secondary */
/* Theme_warn */
$theme-warn-500: white;
/* /Theme_warn */
/* Theme_primary_dark */
$theme-primary-dark-500: white;
/* /Theme_primary_dark */
/* Theme_secondary_dark */
$theme-secondary-dark-500: #db2128;
/* /Theme_secondary_dark */
/* Theme_warn_dark */
$theme-warn-dark-500: black;
/* /Theme_warn_dark */

$theme-primary: theme($theme-primary-500);

$theme-secondary: theme($theme-secondary-500);

/* Theme_warn-var */
$theme-warn: theme($theme-warn-500);
/* /Theme_warn-var */

/* Theme_primary_dark-var */
$theme-primary-dark: theme($theme-primary-dark-500);
/* /Theme_primary_dark-var */

/* Theme_secondary_dark-var */
$theme-secondary-dark: theme($theme-secondary-dark-500);
/* /Theme_secondary_dark-var */

/* Theme_warn_dark-var */
$theme-warn-dark: theme($theme-warn-dark-500);
/* /Theme_warn_dark-var */

/* 테마 설정 변수 */
$primary: $theme-primary;
$secondary: $theme-secondary;
$warn: $theme-warn;

// 폰트 정의
$theme-typography: mat.define-typography-config(
  $font-family: $font-family,
);

$app-theme-light: mat.define-light-theme(
  mat.define-palette($primary),
  mat.define-palette($secondary),
  mat.define-palette(mat.$red-palette)
);

@if (not($warn == null)) {
  $app-theme-light: mat.define-light-theme(
    mat.define-palette($primary),
    mat.define-palette($secondary),
    mat.define-palette($warn)
  );
}

/* 다크 모드 설정 */
@if (not($theme-primary-dark == null)) {
  $primary: $theme-primary-dark;
}

@if (not($theme-secondary-dark == null)) {
  $secondary: $theme-secondary-dark;
}

@if (not($theme-warn-dark == null)) {
  $warn: $theme-warn-dark;
}

$app-theme-dark: mat.define-dark-theme(
  mat.define-palette($primary),
  mat.define-palette($secondary),
  mat.define-palette(mat.$red-palette)
);

@if (not($warn == null)) {
  $app-theme-dark: mat.define-dark-theme(
    mat.define-palette($primary),
    mat.define-palette($secondary),
    mat.define-palette($warn)
  );
}

@include mat.all-component-themes($app-theme-light);
@include mat.core(
  (
    theme: $app-theme-light,
    typography: $theme-typography,
  )
);

// FIXME: class로 전환할 수 있도록 변경
@media (prefers-color-scheme: dark) {
  // @include mat.all-component-colors($app-theme-dark);
}

$sub-warn-color: #f9b448;
$font-color-medium: #464646;
$font-color-light: #646464;
$font-color-lighter: #949494;
$space-color: #f7f7f7;
$separator-color: #ebebeb;
