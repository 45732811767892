/* You can add global styles to this file, and also import other style files */
@use '~@angular/material' as mat;
@import 'theme';
@import 'external';
@import 'material';
@import 'normalize';
@import 'pw';
@import 'structure';

@import 'gloria_g';

// 신규 매장 상세
@import 'description';
@import 'variable';

@import '../../node_modules/swiper/swiper-bundle.css';

// @import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;300;400;500;700;900&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Noto+Serif+JP:wght@100;300;400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css?family=M+PLUS+1p:wght@400;500;700&display=swap');

html {
  font: 400 14px / 20px $font-family;

  scroll-padding-top: 56px;

  @media screen and (min-width: 600px) {
    scroll-padding-top: 128px;
  }
}

* {
  -webkit-tap-highlight-color: transparent;
}

// router link 들어간 요소 모두 손가락 커서
tr,
li,
div,
span,
label,
button {
  &[ng-reflect-router-link] {
    cursor: pointer;
  }
}

.fab-btn-area {
  z-index: 100;
}

// 어두운 배경 위에 클릭 가능한 요소 가이드를 밝게 표시
.highlight-link {
  .fab-btn-area {
    z-index: 101;
  }
  .mat-sidenav-container,
  .mat-sidenav,
  .mat-drawwer-content,
  .mat-drawer.mat-drawer-side,
  .mat-sidenav-content,
  .mat-tab-link-container {
    z-index: initial;
  }
  .menu-tabbar {
    z-index: 101;
  }
  .need-guide {
    position: relative;
    z-index: 102;
    border: 1px solid red !important;
    box-sizing: border-box;
    box-shadow: 0 0 0 2px red !important;
    filter: brightness(1.2);
  }
}

// 팝업 모달 기본 패딩 제거
.modal-no-padding {
  mat-dialog-container {
    padding: 0;
  }
}

.popup {
  max-width: 480px !important;
  mat-dialog-container {
    max-width: 480px;
  }
}

.popup-confirm {
  max-width: 1248px !important;
  mat-dialog-container {
    max-width: 1248px;
  }
}

.mobile-popup-with-header {
  width: 100vw !important;
  height: calc(100vh - 50px);
  position: fixed;
  top: 50px;
  left: 0;
  bottom: 0;
  background-color: #fff;
}

.mobile-popup {
  width: 100vw !important;
  overflow: auto;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  background-color: #fff;
}

// mat-drawer-container 배경 회색 제거
.mat-drawer-container {
  background-color: transparent;
}

a {
  text-decoration: none;
  color: inherit;
}

.font-display-4 {
  font-size: 60px;
}

.font-title {
  font-size: 36px;
}

.font-subheading-2 {
  font-size: 30px;
}

.font-headline {
  font-size: 24px;
}

.font-subheading-1 {
  font-size: 22px;
}

.font-body {
  font-size: 16px;
}

.font-caption {
  font-size: 14px;
}

.font-input {
  font-size: 16px;
}

.font-button {
  font-size: 20px;
}

.font-ruby {
  font-size: 0.8em;
  margin-bottom: 2px !important;
}

.mobile {
  .font-display-4 {
    font-size: 20px;
  }

  .font-title {
    font-size: 14px;
  }

  .font-subheading-2 {
    font-size: 12px;
  }

  .font-headline {
    font-size: 11px;
  }

  .font-subheading-1 {
    font-size: 12px;
  }

  .font-body {
    font-size: 12px;
  }

  .font-caption {
    font-size: 11px;
  }

  .font-input {
    font-size: 12px;
  }

  .font-button {
    font-size: 12px;
  }
}

select {
  -webkit-appearance: none;
  background-image: url(/assets/img/select-arrow-down.png);
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: calc(100% - 10px) center;
  border-radius: 0;
}

// 시설의 이미지가 pw-img 컴포넌트 내에서 높이 100%로 채우게 변경
pw-img.full-height img {
  object-fit: cover;
  min-height: 100%;
}

app-facility-detail {
  pw-img.full-height img {
    object-fit: cover;
  }
}

// 시설의 이미지가 pw-img 컴포넌트 내에서 중앙에서 크기만큼 채우게 변경
pw-img.none-img img {
  object-fit: scale-down;
}

.fullscreen-modal {
  max-width: 100vw !important;
  width: 100vw;
  max-height: 100vh !important;
  height: 100vh;
  .mat-dialog-container {
    border-radius: 0;
    padding: 0;
  }

  #ifrWrapper {
    iframe {
      width: 100vw;
      height: 100vh;
    }
  }
}

.gm-style-iw {
  padding: 0 !important;
}

.gm-style-iw-d {
  overflow: unset !important;
}

.gm-ui-hover-effect {
  display: none !important;
}

.mat-form-field-invalid .mat-form-filed-label {
  color: rgba(0, 0, 0, 0.54) !important;
}

.mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: rgba(0, 0, 0, 0.54) !important;
}

.mat-form-field.mat-form-field-invalid .mat-form-field-ripple {
  opacity: 0 !important;
  transform: unset !important;
  transition: unset !important;
}

[spacer] {
  width: 100%;
  height: 8px;
  background-color: $space-color;
}

.swiper-slide {
  height: unset;
  min-height: 100%;
}

.swiper-css-mode.mobile > .swiper-wrapper > .swiper-slide {
  padding-left: 16px;
  margin: 0 !important;
  &:first-child {
    padding-left: 16px;
  }
  &:last-child {
    padding-right: 16px;
  }
}

// 홈 공지사항 제목 innerHtml
.ntc-title {
  a {
    color: #333;
    text-decoration: underline;
  }

  a:hover {
    text-decoration: none;
  }
}

.transparent-dialog {
  @media (min-width: 1280px) {
    max-width: 80vw;
  }
  .mat-dialog-container {
    background-color: transparent;
    box-shadow: none;
  }
}

// 예약 검색 모바일 placeholder
.schedule-title-container {
  .mat-date-range-input-start-wrapper {
    overflow: inherit;
    max-width: fit-content;
  }
}

.confirm-content-input-group > app-input-wrapper > div .error-text-box {
  background-color: #fcfcfc;
}

.zipcode-panel {
  max-width: 100vw;
  max-height: 100vh;
  width: 100vw;
  height: 100vh;

  mat-dialog-container {
    padding: 0;
  }

  .mat-dialog-container {
    border-radius: 0;
  }

  @media screen and (min-width: 600px) {
    max-width: 80vw;
    max-height: 80vh;
    width: auto;
    height: auto;

    .mat-dialog-container {
      border-radius: 4px;
    }
  }
}

[input-wrapper] {
  font-size: 14px;

  [input-label] {
    display: block;
    margin-bottom: 8px;

    &[required]::after {
      content: '*';
      color: red;
    }
  }

  input,
  select {
    $padding: 16px;
    $height: 40px;

    display: block;
    box-sizing: border-box;
    outline: none;
    border: 1px solid #b7b7b7;
    padding: 0 $padding;
    width: calc(100% - #{$padding} * 2);
    height: $height;
    line-height: $height;

    @media screen and (min-width: $min-width-m) {
      $height: 60px;
      height: $height;
      line-height: $height;
    }
  }

  [input-error] {
    margin-top: 8px;
    display: block;
    color: red;
  }

  @media screen and (min-width: $min-width-m) {
    font-size: $html-font-size;
  }
}

[trnty-button] {
  $height: 40px;

  box-sizing: border-box;
  border: 1px solid $primary-color;
  border-radius: $border-radius;
  padding: 0 16px;
  height: $height;
  line-height: $height;

  @include primary-theme;
  // font-size: 1.125rem;
  cursor: pointer;

  &:disabled {
    border: 1px solid $border-color-2;
    background-color: $border-color-2;
    color: $border-color-1;
  }

  @media screen and (min-width: $min-width-m) {
    $height: 60px;
    height: $height;
    line-height: $height;
  }
}

[trnty-button='secondary'] {
  border: 1px solid $secondary-color;
  // background-color: $secondary-color;
  // color: $content-color-1;
  @include secondary-theme;
}

[trnty-button='warn'] {
  border: 1px solid $border-color-1;
  background-color: #fff;
  color: $warn-color;
}

app-facility {
  app-my-rsrv-detail {
    margin: auto;
    padding: 60px 0;
    display: block;
    max-width: 1280px;
  }
}

// 시설 상세 문단 FREE 타입 본문 래퍼
.description-content-wrapper {
  // 구 글로리아 홈페이지에서 가져온 데이터가 문제이므로 해제하려면 해당 데이터들의 자연소멸 확인 필요
  // 시설 상세 문단 모든 폰트를 html 데이터에 관계 없이 통일
  * {
    font-family: inherit !important;
    font-size: inherit !important;
    letter-spacing: inherit !important;
    line-height: inherit !important;
  }

  a {
    // 관리자 서머노트 하이퍼링크 색상
    color: #337ab7;
  }

  ul,
  li {
    // 리스트 스타일 복원
    list-style: revert;
  }
}

// 시설 상세 이미지 확대 다이얼로그
.img-viewer-dialog-panel {
  width: 100vw;
  height: 100vh;

  mat-dialog-container {
    border-radius: 0;
    padding: 0;
    background-color: #000;
  }

  @media screen and (min-width: $min-width-m) {
    width: 95vw;
    height: 95vh;
  }
}
